
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Typography, Button, Box } from '@mui/material';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'; 


import NavBar from '../../components/navBar/NavBar';
import Footer from '../../components/footer/Footer';

import { getProductById } from '../../utils/products/products';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './Detail.Module.css';
import product1 from "../../assets/images/CORAZON-VERDE-TRANS.png"
import product2 from "../../assets/images/CORAZON-VERDE-TRANS-FONDO.png"
import Loader from '../../components/loader/Loader';
import { agregarAlCarrito } from '../../features-slice/cart/carritoSlice';
import { currencyDisplay } from '../../utils/cart/cart';
import { Toaster, toast } from 'react-hot-toast';
import { isNewProduct } from '../../utils/products/products';
import vegan from '../../assets/Sellos/VEGAN.png';
import { isAuthenticated } from '../../utils/auth/auth';
import { useMediaQuery } from 'react-responsive';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Carousel from 'react-multi-carousel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import christmas from "../../assets/BeautiChristmas_5.png"



export default function Detail() {
    const [producto, setProducto] = React.useState({});
    const img1 = producto.img ? producto.img : product1;
    const img2 = producto.img2 ? producto.img2 : product2;
    const [loader, setLoader] = React.useState(true);
    const dispatch = useDispatch();
    const { id } = useParams();
    
	const searchParam = new URLSearchParams(window.location.search).get('termino');
    const [cambioImg, setCambioImg] = React.useState(false);
    const handleMouseEnter = () => {
        setCambioImg(true);
    };
    const navigate = useNavigate();
    const [showVariantSelector, setShowVariantSelector] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedVarianteId, setSelectedVarianteId] = React.useState('');
    
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    React.useEffect(() => {
        toast.remove();
        const getProducto = async () => {
            setProducto(await getProductById(id));
            setLoader(false);
        };
        getProducto();
        return () => {
            setProducto({});
        };
    }, [id]);

    function manejadorAgregarACarrito() {
        if (selectedVarianteId) {
            const carritoItem = {
                ...producto,
                cantidad: 1,
                varianteId: selectedVarianteId,
            };

            dispatch(agregarAlCarrito(carritoItem));
        } else {
            dispatch(agregarAlCarrito({ ...producto, cantidad: 1 }));
        }
    }


    const urlCatalog  = `/market/categoria/64ef4986d269731b208134bf?nombre=MARKET&termino=${searchParam}`


		const isMobileScreen = useMediaQuery({ maxWidth: 768 }); // Cambio de imagen en pantallas pequeñas
		    const responsive = {
		        desktop: {
		            breakpoint: { max: 3000, min: 1024 },
		            items: 1,
		            slidesToSlide: 1,
		        },
		        tablet: {
		            breakpoint: { max: 1024, min: 464 },
		            items: 1,
		            slidesToSlide: 1,
		        },
		        mobile: {
		            breakpoint: { max: 464, min: 0 },
		            items: 1,
		            slidesToSlide: 1,
		        },
		    };
		
            const procesarCadena = (cadena) => {
                if (cadena && cadena[0].match(/\d/)) {
                    return cadena.slice(1);
                } else {
                    return cadena;
                }
            };
    const handleImageClick = () => {
        setOpenDialog(true);
    };

    return (
        <div className='fondoDetail'>
            <header>
                <NavBar />
            </header>
            
            <div className='atrasTitle'>
						
                            </div>
            <main className='containerDetail py-4'>
                {loader ? (
                    <Loader />
                ) : (

                   <>
                   <button style={{ border:"none",width:"80px",marginBottom:isMobileScreen? "5vh" : "85px", top:!isMobileScreen && "50vh", left:"-5vw", backgroundColor:"#20563f!important"}} className='fondoPrevButton buttonCata' onClick={() => searchParam !== undefined && searchParam !== "undefined" && searchParam !== "" ? navigate(urlCatalog): navigate(-1)}>


                            <ChevronLeftIcon style={{ fill: "white" }} />
                            Back
                        </button>
                        <Card style={{ marginTop:!isMobileScreen? "20vh":"30vh", marginBottom: "65px", width: isMobileScreen ? "100%" : "60%", height: !isMobileScreen ? "550px" : "" }}>
                                <Box display="flex" flexDirection={isMobileScreen ? "column !important" : "row"}>
                                    <Toaster
                                        position='bottom-left'
                                        reverseOrder={false}
                                        toastOptions={{ className: 'fondoToast' }} />

                                    <Box style={{ position: !isMobileScreen ? "relative" : "", width: isMobileScreen ? "100%" : "50%", border: "none", marginBottom: "25px", height: "100%" }} flex={1}>

                                        <div style={{ position: "absolute", zIndex: 1 }} className=' ofertasDetail'>
                                            {producto.precioOferta > 0 && (
                                                <img className='christmas-detail' src={christmas} alt='Navidad' />
                                                // <span className='badge new-sale-detail'>#BEAUTI FRIDAY</span>
                                            )}
                                            {producto.fechaCreacion
                                                ? isNewProduct(producto.fechaCreacion) && (
                                                    <span className='badge bg-info text-white new-sale-detail'>NEW</span>
                                                )
                                                : ''}

                                            {producto.vegan && (
                                                <abbr className='veganDetail' title='Producto vegano'>
                                                    <img className='vegan' src={vegan} alt='Vegano' />
                                                </abbr>
                                            )}

                                        </div>
                                        {/* <div style={{ marginLeft: isMobileScreen ? "-25px" : "", width: "100%", display: "flex", justifyContent: "flex-end", position: "absolute", zIndex: 1 }} className="zoom-icon-container">
                                            <ZoomInIcon onClick={handleImageClick} className="zoom-icon" />
                                        </div> */}
                                        <Carousel

                                            className={`detailCarouselCartilla`}
                                            draggable={true}
                                            showDots={false}
                                            swipeable={false}
                                            responsive={responsive}
                                            infinite={true}
                                            autoPlay={false}
                                            keyBoardControl={true}
                                            autoPlaySpeed={3000}
                                            containerClass='carousel-container-cartilla'
                                            // removeArrowOnDeviceType={['tablet', 'mobile', "desktop"]}
                                        >

                                        <TransformWrapper>
                                            <TransformComponent >
                                            <img src={img1} alt={`Imagen de`} style={{
                                                            minWidth:isMobileScreen?"100vw":"500px",
                                                                minHeight: isMobileScreen?"330px":"500px",
                                                                maxHeight: isMobileScreen?"330px":"550px",
                                                                objectFit: "cover"
                                                            }} />
                                            </TransformComponent>
                                        </TransformWrapper>

                                        <TransformWrapper>
                                            <TransformComponent  >
                                            <img src={img2} alt={`Imagen de`} 
                                                            style={{
                                                            minWidth:isMobileScreen?"100vw":"500px",
                                                                minHeight: isMobileScreen?"330px":"500px",
                                                                maxHeight: isMobileScreen?"330px":"550px",
                                                                objectFit: "cover"
                                                            }} />
                                            </TransformComponent>
                                        </TransformWrapper>

                                        </Carousel>
                                    </Box>
                                    <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", border: "none", height: !isMobileScreen && "500px" }} flex={1} p={2}>




                                        <Box>

                                        <Typography style={{ fontSize: isMobileScreen ? "12px" : "14px", fontFamily: "Montserrat" }} variant="h6" gutterBottom>
                                        {producto.marca}
                                        </Typography>

                                            <Typography style={{ fontSize: isMobileScreen ? "18px" : "24px", fontFamily: "Montserrat" }} variant="h6" gutterBottom>

                                                {procesarCadena(producto.nombre)}

                                            </Typography>


                                            

                                        </Box>
                                        <Typography variant="body2">
                                            {producto?.descripcion?.split('/n').map((paragraph, index) => (
                                                <>
                                                    <Typography key={index} style={{ fontFamily: "Montserrat", textAlign: "center" }} gutterBottom>
                                                        {paragraph.split(/(\*.*?:)/).map((text, index) => {
                                                            if (text.startsWith('*')) {
                                                                return <strong key={index}>{text}</strong>;
                                                            } else {
                                                                return text;
                                                            }
                                                        })}
                                                    </Typography>
                                                    <br></br>
                                                </>
                                            ))}
                                        </Typography>

                                        <div className='d-flex justify-content-center align-items-center '>

                                                <small className='text-muted paddingSmall'>
                                                    {/* <i className='bx bxs-purchase-tag-alt'></i> */}
                                                    {producto?.precioOferta > 0 ? (
                                                        <small style={{ fontWeight: 600, fontSize: isMobileScreen ? "14px" : "18px" }} className='text-muted oferta'>
                                                            <Typography>Producto en oferta</Typography>
                                                            <s>{currencyDisplay(producto.precioTarjeta)}</s>
                                                            {currencyDisplay(producto.precioOferta)}
                                                        </small>
                                                    ) : (
                                                        <small style={{ fontWeight: 600, fontSize: isMobileScreen ? "14px" : "18px" }} className='text-muted '>
                                                            {currencyDisplay(producto.precioTarjeta)}
                                                        </small>
                                                    )}
                                                </small>
                                            </div>

                                            <Typography style={{ fontSize: isMobileScreen ? "12px" : "14px", fontFamily: "Montserrat", fontWeight:600 }} variant="h6" gutterBottom>

                                            *Accede a los precios promo abonando contado efectivo en el local.

                                            </Typography>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            {
                                            (
                                                <div>
                                                    {producto?.variantes?.length === 0 ? (
                                                        <button
                                                            style={{ width: isMobileScreen ? "100vw" : "" }}
                                                            className='btn btn-sm btn-outline-secondary '
                                                            onClick={manejadorAgregarACarrito}
                                                        >
                                                            <small className='text-muted'>LO QUIERO</small>
                                                        </button>
                                                    ) : (
                                                        <>
                                                            {!selectedVarianteId && !showVariantSelector && (
                                                                <button
                                                                    style={{ width: isMobileScreen ? "100vw" : "" }}
                                                                    className='btn btn-sm btn-outline-secondary'
                                                                    onClick={() => setShowVariantSelector(true)}
                                                                >
                                                                    <small className='text-muted'>LO QUIERO</small>
                                                                </button>
                                                            )}

                                                            {showVariantSelector && producto?.variantes?.length > 0 && (
                                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "5px" }}>
                                                                    <select
                                                                    style={{borderRadius:"5px", height:"45px"}}
                                                                        value={selectedVarianteId}
                                                                        onChange={(e) => setSelectedVarianteId(e.target.value)}
                                                                    >
                                                                        <option value=''>Variantes</option>
                                                                        {producto?.variantes.filter((variante) => variante.disponibles > 0).map((variante) => (
                                                                            <option key={variante._id} value={variante._id}>
                                                                                {variante.color}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <button
                                                                        className='btn btn-sm btn-outline-secondary carrito'
                                                                        onClick={manejadorAgregarACarrito}
                                                                        disabled={selectedVarianteId === ''}
                                                                    >
                                                                        <small className='text-muted'>Agregar</small>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>


                                    </Box>
                                </Box>

                            </Card></>
                )}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
  <DialogContent sx={{ maxWidth: 'none', width: '1200px' }}>
    <img src={cambioImg ? img2 : img1} alt={`Imagen de ${producto?.nombre}`} style={{ width: '100%' }} />
  </DialogContent>
</Dialog>

            </main>
            {/* <GloboContacto /> */}
            <footer>
                <Footer />
            </footer>
        </div>
    );
}
